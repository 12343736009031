import styled from '@emotion/styled'
import { TimeUtil, useFeatureFlags } from 'cuenect-web-core'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AdhocService } from '../api'
import { ContentContainer, InnerContainer } from '../components'
import {
  BigCountdown,
  Button,
  ContentColumn,
  Heading,
  KeyVisual,
  LineContainer,
  Paragraph,
  SieArrow,
} from '../components'
import { Cognisphere } from '../components/molecules/cognisphere/cognisphere'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { siemensTheme } from './../themes/siemens/siemensTheme'
import { siemensThemeDarkHeader } from './../themes/siemens/siemensThemeDarkHeader'
import {
  mq,
  Spacer,
  useGlobalContext,
  useModalContext,
  useVodContext,
} from './../utility'

import { isPostEvent, isPostEventFirst } from './../config'
import { isPostStream } from '../config/event'

const HIDE_MEET_THE_EXPERT_TIME = '2021-10-07T16:00Z'
const HIDE_LIVE_TIME = '2021-10-05T16:00Z'
const RENAME_AUDITORIUM = '2021-12-01T04:00Z'

const getHideMte = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(HIDE_MEET_THE_EXPERT_TIME)
}

const getHideLive = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(HIDE_LIVE_TIME)
}

const getRenameAuditorium = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(RENAME_AUDITORIUM)
}

const AD_HOC_BUTTON_HEIGHT = 57
const AD_HOC_BUTTON_PADDING = 2.5 * 16
const SHOW_SMALL_AD_HOC_BUTTON_THRESHOLD =
  AD_HOC_BUTTON_HEIGHT + AD_HOC_BUTTON_PADDING

const IndexPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const { dispatch: openVod } = useVodContext()
  const {
    state: { status: gobalStatus },
  } = useGlobalContext()

  const [loaded, setLoaded] = React.useState(false)
  const [countdownCompleted, setCountdownCompleted] = React.useState(false)

  const { phase2, phase3 } = useFeatureFlags()
  const postEvent = isPostEvent()
  const postStream = isPostStream()

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en${document.location.search}`)
    }

    return <></>
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  const { t } = useTranslation(['index', 'countdown'])

  const onTeaserClick = (url: string) => {
    navigate(`/${lang}${url}`)
  }

  return (
    <PageFrame {...params} pageName="index" isTop perectScroll={false}>
      {loaded && (
        <>
          <KeyVisual hideScrollCta>
            <Cognisphere visual="/assets/keyvisual.jpg" asImage />

            <ContentContainer fullheight>
              <StartTeaser>
                <LineContainer className="LineContainer">
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('topline')}
                  </Heading>
                  <Heading>{parse(t('header'))}</Heading>
                </LineContainer>

                <IntroTextContainer>
                  <Paragraph>{parse(t('intro'))}</Paragraph>
                </IntroTextContainer>
              </StartTeaser>
            </ContentContainer>
          </KeyVisual>

          {!countdownCompleted && (
            <>
              <ContentContainer>
                <BigCountdown
                  onComplete={() => setCountdownCompleted(true)}
                  headline={
                    <Heading type="h4">{t('countdown:headline')}</Heading>
                  }
                />
              </ContentContainer>
              <Spacer />
            </>
          )}

          <ContentContainer align="center">
            {!postEvent && (
              <StartTeaser>
                <LineContainer className="LineContainer">
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('teaser.topline')}
                  </Heading>
                  <Heading type="h2">{parse(t(`teaser.header`))}</Heading>
                </LineContainer>
                <Paragraph>{parse(t('teaser.copy'))}</Paragraph>
                {!postStream && (
                  <div>
                    <Button onClick={() => navigate(t(`teaser.cta.link`))}>
                      {t('teaser.cta.label')}
                    </Button>
                  </div>
                )}
              </StartTeaser>
            )}
            {postEvent && (
              <StartTeaser>
                <LineContainer className="LineContainer">
                  <Heading type="h6" spacing={{ bottom: 0.8 }}>
                    {t('teaserPhase4.topline')}
                  </Heading>
                  <Heading type="h2">{parse(t(`teaserPhase4.header`))}</Heading>
                </LineContainer>
                <Paragraph>{parse(t('teaserPhase4.copy'))}</Paragraph>
                <div>
                  <Button onClick={() => navigate(t(`teaserPhase4.cta.link`))}>
                    {t('teaserPhase4.cta.label')}
                  </Button>
                </div>
              </StartTeaser>
            )}
          </ContentContainer>

          <Spacer />
          {!postEvent && (
            <>
              <ContentContainer>
                <ContentColumn
                  left={<img src="/assets/index/01.jpg" />}
                  right={
                    <div>
                      <Heading type="h4">
                        {postStream ? t('row1Phase4.header') : t('row1.header')}
                      </Heading>
                      <Paragraph>
                        {postStream
                          ? t('row1Phase4.copy')
                          : parse(t('row1.copy'))}
                      </Paragraph>
                      <Button
                        plain
                        colored
                        small
                        icon={SieArrow}
                        iconAlign="right"
                        active
                        onClick={() =>
                          navigate(
                            postStream
                              ? t('row1Phase4.link.href')
                              : t('row1.link.href')
                          )
                        }
                      >
                        {postStream
                          ? t('row1Phase4.link.text')
                          : parse(t('row1.link.text'))}
                      </Button>
                    </div>
                  }
                />
              </ContentContainer>
              <Spacer />
              <ContentContainer>
                <ContentColumn
                  revertMobile
                  right={<img src="/assets/index/02.jpg" />}
                  left={
                    <div>
                      <Heading type="h4">{t('row2.header')}</Heading>
                      <Paragraph>{parse(t('row2.copy'))}</Paragraph>
                      <Button
                        plain
                        colored
                        small
                        icon={SieArrow}
                        iconAlign="right"
                        active
                        onClick={() => navigate(t('row2.link.href'))}
                      >
                        {t('row2.link.text')}
                      </Button>
                    </div>
                  }
                />
              </ContentContainer>
              <Spacer />
              <ContentContainer>
                <ContentColumn
                  left={<img src="/assets/index/03.jpg" />}
                  right={
                    <div>
                      <Heading type="h4">{t('row3.header')}</Heading>
                      <Paragraph>{parse(t('row3.copy'))}</Paragraph>
                      <Button
                        plain
                        colored
                        small
                        icon={SieArrow}
                        iconAlign="right"
                        active
                        onClick={() => navigate(t('row3.link.href'))}
                      >
                        {t('row3.link.text')}
                      </Button>
                    </div>
                  }
                />
              </ContentContainer>
            </>
          )}
        </>
      )}
    </PageFrame>
  )
}

export default IndexPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
}

const StartTeaser = styled.div(({ theme }) => ({
  marginLeft: `${100 / theme.grid.columns}vw`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  [mq[3]]: {
    width: '60%',
  },
}))

const IntroTextContainer = styled.div({ marginTop: '1rem' })

const TeaserContainer = styled(ContentContainer)({ margin: '3rem 0' })

const StartTeaserAlt = styled.div<StartTeaserProps>(
  ({ theme: { grid }, fullheight, width }) => `
  height: 100%;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight &&
    `min-height:calc(100vh - 220px);
    margin-top:100px;
    ${[mq[2]]}{
      margin-top:0px;
      display:flex;
      align-items:center;
    }`
  }

  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)
